<template>
  <div class="blob-selector-modal-wrapper ">
    <div class="blob-selector-icon-wrapper">
      <b-icon-folder v-on:click="openModal()" class="aoi-icon"></b-icon-folder>
    </div>
    <b-modal
            ref="azureBlobSelector"
            id="azureBlobSelectorModal"
            centered
            size="lg"
            hide-footer
            header-class="blob-selector-header"
            title="Select Video"
    >
      <div class="flex row azure-blob-selector-wrapper">
        <div  class="azure-blob-selector-containers-wrapper w40">
          <div class="title-wrapper">
            <span>Containers</span>
          </div>
          <div class="list-wrapper">
            <div :class="{'active-container' : item === blobDetails.containerName}" v-for="item in blobContainers" :key="item" v-on:click="handleContainerClick(item)" class="abs-item-row flex-row">
              <div :id="'container' + item" class="abs-item-name">{{ item }}</div>
              <b-tooltip :target="'container' + item" noninteractive>{{item}}</b-tooltip>
              <div v-if="item === blobDetails.containerName"><b-icon-play-fill class="active-container-icon"></b-icon-play-fill></div>
            </div>
          </div>
        </div>
        <div class="azure-blob-selector-folders-wrapper w60">
          <div class="title-wrapper">
            <b-breadcrumb class="video-selector-breadcrumb">
              <b-breadcrumb-item class="nash-breadcrumb-item" @click="onBreadcrumbClick(index)"  v-for="(item, index) in selectedFolders" v-b-tooltip="item.text" :active="item.active" :key="index">{{item.text}}</b-breadcrumb-item>
            </b-breadcrumb>
          </div>
          <div class="no-data-text" v-if="!blobDetails.containerName">
            <span>Select container where your video is located</span>
          </div>
          <div class="list-wrapper" v-if="blobDetails.containerName">
            <div v-for="item in folderItems" v-on:click="handleItemClick($event, item)" class="abs-item-row flex-row" :key="item.fullPath">
              <div v-if="item.isFolder!=true" class="abs-item-icon"><b-icon-file-earmark></b-icon-file-earmark></div>
              <div v-if="item.isFolder==true" class="abs-item-icon"><b-icon-folder></b-icon-folder></div>
              <div class="abs-item-name" :id="'file' + item.fullPath">{{ item.name }}</div>
              <b-tooltip :target="'file' + item.fullPath" noninteractive>{{item.name}}</b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>

</template>

<script>
export default {
    name: "AzureBlobSelector",
    data() {
      return {
        selectedFolders: [],
        displayConfig: false,
        displayLoader: false,
      }
    },
    computed: {
      blobContainers() {
        return this.$store.state.azureBlobData.data.blobContainers;
      },
      blobDetails() {
        return this.$store.state.azureBlobData.data.blobDetails;
      },
      folderItems() {
        return this.$store.state.azureBlobData.data.blobFiles.map(file => {
          if(file.name.length > 1 && file.name.endsWith("/")){
            file.name = file.name.substring(0, file.name.length - 1)
          }
          if(file.name.length > 1 && file.name.indexOf("/") !== -1) {
            file.name = file.name.substring(file.name.lastIndexOf("/") + 1, file.name.length)
          }
          return file;
        });
      }
    },
    beforeMount() {
      this.$store.commit("SET_SELECTED_CONTAINER", null);
      this.$store.commit("CLEAR_BLOB_FILES")
    },

    methods: {
      closeModal() {
        this.$refs.azureBlobSelector.hide();
      },
      openModal() {
        this.$store.dispatch("loadBlobStorageContainers", {}).then(response=>{
          if(this.blobContainers && this.blobContainers[0]) {
            this.handleContainerClick(this.blobContainers[0]);
          }
        });
        this.$refs.azureBlobSelector.show();
      },
      onBreadcrumbClick(index) {
        if(index === 0){
          this.$store.dispatch("loadAzureBlobData", {path: ''});
        } else {
          this.$store.dispatch("loadAzureBlobData", {path: this.selectedFolders[index].path});
        }
        this.selectedFolders = this.selectedFolders.slice(0, index + 1)
      },

      handleContainerClick(containerName) {
        this.$store.commit("CLEAR_BLOB_FILES");
        this.$store.commit("SET_SELECTED_CONTAINER", containerName);
        this.selectedFolders = [];
        this.selectedFolders.push( {
          text: containerName,
          active: true
        });
        this.$store.dispatch("loadAzureBlobData", {path: ''});
      },
      handleItemClick(event, item) {
        event.stopPropagation();
        if (item.isFolder === false) {
          const srcObj = {
            src: item.fullPath,
            name: item.name,
            type: item.contentType,
            container: item.container
          };
          this.$emit('onFileSelected', srcObj);
          this.closeModal();
        }
        else {
          this.selectedFolders = this.selectedFolders.map(a => {
            a.active = false;
            return a;
          });
          this.selectedFolders.push( {
            text: item.name,
            path: item.fullPath,
            active: true
          });
          this.$store.dispatch("loadAzureBlobData", {path: item.fullPath});
        }
      }
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300');
.content {
  opacity: 1.0 !important;
}
.w40 {
  width: 40%;
}
.w60 {
  width: 60%;
}
.azure-blob-selector-wrapper {
  margin-top: 50px;
  width: 100%;
  height: 250px;
  background-color: rgb(33, 44, 56);
  padding: 0;
  color: #333;
  overflow-x: hidden;
  overflow-y: hidden;
  .title-wrapper {
    color: #CED6E0;
    font-size: 16px;
    padding-left: 14px;
    padding-bottom: 3px;
    border-bottom: solid 1px #4B5561;
  }
  .list-wrapper{
    margin-top: 10px;
    margin-left: 10px;
    overflow-y: auto;
    font-size: 14px
  }
  .azure-blob-selector-containers-wrapper {
    height: 100%;
    border-right: solid 1px #4B5561;
    .list-wrapper{
      margin-top: 10px;
      height: calc(100% - 50px);
    }
    .title-wrapper {
      padding-bottom: 10px;
    }
    .active-container {
      border: solid rgb(13, 150, 255);
      border-radius: 5px;
      justify-content: space-between;
      .abs-item-name{
        max-width: 80%;
      }
      .active-container-icon{
        color: rgb(13, 150, 255);
      }
    }
  }
  .azure-blob-selector-folders-wrapper {
    height: 100%;
    .no-data-text {
      display: flex;
      height: calc(100% - 40px);
      align-items: center;
      color: #CED6E0;
      padding: 25px;
    }
    .list-wrapper{
      height: calc(100% - 50px);
    }
    .video-selector-breadcrumb{
      padding: 0;
      font-size: 14px;
      background: inherit;
      min-height: 21px;
      margin-bottom: 10px;
      .nash-breadcrumb-item {
        > a {
          color: rgb(13, 150, 255);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        > span {
          text-overflow: ellipsis;
          max-width: 100px;
          white-space: nowrap;
          overflow: hidden;
        }
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .abs-item-row {
    padding: 0px 6px !important;
    display: flex;
    height: 28px;
    align-items: center !important;
    // width: 95%;
    // padding: 1px 10px 2.5px 5px;
    // height: 30px;
    color: #CED6E0;
    // align-items: flex-start !important;
  }
  .abs-item-row:hover {
    color: #ddd;
    cursor: pointer;
    background-color: rgba(13, 150, 255, .3);
  }
  .abs-item-icon {
    width: 15px;
    height: 23px;
    margin-right: 5px;
    color: #CED6E0;
  }
  .hidden {
    display: none;
  }
}
.validation-error {
  .blob-selector-icon-wrapper {
    border-color: #FF526F;
    color: #FF526F;
    .aoi-icon {
      color: #FF526F!important;
    }
  }


}

.blob-selector-icon-wrapper {
  border: solid thin;
  margin-top: 8px;
  padding: 6px 6px 2px;
  margin-left: 10px;
  border-radius: 0.3em;
  color: white;
  .aoi-icon {
    color: inherit;
    font-size: 20px;
  }
  .aoi-icon:focus{
    outline: none;
  }
}
  .blob-selector-header {
    border-bottom: none;
    h5 {
        color: #CED6E0;
        text-align: left!important;
        margin-left: 25px!important;
    }
    button {
      line-height: 0.6;
      align-self: center;
      padding: 1px 5px 5px 5px!important;
      margin: 0!important;
      font-size: 1.5em!important;
      color: #CED6E0 !important;
      border-radius: 5px;
    }
    button:focus {
      outline: none;
    }
    button:hover {
      background-color: #fff !important;
      color: rgb(33, 44, 56) !important;
      font-size: 16px;
    }
    .header-cancel-button:hover {

    }


  }

.modal-backdrop {
  opacity: 0.8;
}
</style>
