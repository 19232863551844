<template>
    <div class="aoi-form-wrapper scene-wrapper">
        <div class="double-wrapper flex row no-wrap">
            <div class="h-100 column w-100" >
                <b-form-group class="scene-form-group aoi-field-wrapper" :class="{'edit-readonly': this.isEdit}">
                    <label class="aoi-label">Workspace Name</label>
                    <div class="flex row no-wrap m-0">
                        <div class="column w-100">
                            <b-form-input @input="emitNameChange" :class="{'validation-error': $v.scene.name.$error }" :state="validateState('name')"
                                          v-model="$v.scene.name.$model" class="aoi-field aoi-field-gray-bg"  :disabled="isEdit===true"></b-form-input>
                            <b-form-invalid-feedback v-if="!$v.scene.name.required" >Name must not be empty.</b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.scene.name.isUnique" >This name already exists.</b-form-invalid-feedback>
                        </div>
                    </div>
                </b-form-group>
                <b-form-group class="scene-form-group aoi-field-wrapper" :class="{'edit-readonly': this.isEdit}">
                    <label class="aoi-label">Video Selection</label>
                    <b-icon-info-circle class="aoi-label" v-b-tooltip.hover title="Supported File Types:  MP4, MOV"></b-icon-info-circle>
                    <div class="flex row no-wrap m-0">
                        <div class="column w-100">
                             <b-form-input v-b-tooltip="'Format for video selection is `StorageContainer | path/to/file`'" readonly :state="validateState('filePath')" :value="$v.scene.filePath.$model ? $v.scene.containerName.$model + ' | ' + $v.scene.filePath.$model : ''" class="aoi-field aoi-field-gray-bg"  :disabled="isEdit===true"></b-form-input>
                            <b-form-invalid-feedback v-if="!$v.scene.filePath.required">Invalid or missing filepath</b-form-invalid-feedback>
                        </div>
                        <azure-blob-selector @onFileSelected="onFileSelected" :class="{hidden:isEdit === true, 'validation-error': $v.scene.filePath.$error}"></azure-blob-selector>
                    </div>
                </b-form-group>
            </div>
        </div>
        <div class="flex column video-wrapper" :class="{hidden:previewSource === ''}">
            <div class="aoi-label">Preview</div>
            <div class="scene-preview-video-player">
                <video ref="videopreviewRef" v-on:loadedmetadata="onLoadedVideoMetadata($event)" :src="previewSource" controls height="190" muted="true" disablePictureInPicture></video>
            </div>
        </div>
    </div>
</template>

<script>
import AzureBlobSelector from "@/components/AzureBlobSelector.vue";
import { required, sameAs, numeric, minValue, maxValue } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
export default {
    name: "sceneConfiguration",
    mixins: [validationMixin],
    components: {
        AzureBlobSelector
    },
    props: {
    },
    beforeMount(){
        this.scene = this.$parent.modelObject.scene;
        this.isEdit = this.$route.params.id !== 'new';
        this.emitNameChange();
        if(this.isEdit){
            this.setPreviewSource(this.scene.containerName, this.scene.filePath)
        } else {
            if(!this.$store.state.pipelineConfigs.data.pipelineConfigs || this.$store.state.pipelineConfigs.data.pipelineConfigs.length === 0) {
                this.$store.dispatch('getPipelineConfigs');
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if(to.name === 'completion' || to.name === 'behaviors') {
            to.params.scene = this.$v.scene;
            if(this.$v.scene.$invalid){
                this.isFormValid()
            } else {
                if(to.name === 'completion') {
                    if(!this.$route.params.behaviors || (this.$route.params.behaviors && !this.$route.params.behaviors.$invalid)) {
                        to.params.behavior = this.$route.params.behaviors;
                        next()
                    }
                } else {
                    next()
                }
            }
        } else {
            next()
        }
    },
    data() {
      return {
          scene: null,
          previewSource: '',
          isEdit: false,
          behaviors: null,
      }
    },
    validations: {
        scene: {
            name: {
                required,
                isUnique(value) {
                    if(value === '' || this.isEdit) return true;
                    return !this.names.find(a => a.toLowerCase().trim() === this.$v.scene.name.$model.toLowerCase().trim())
                }
            },
            containerName: {
            },
            filePath: {
                required
            }
        }
    },
    computed: {
        names() {
            return this.$store.state.pipelineConfigs.data.pipelineConfigs.map(a => a.name)
        },
    },
    methods: {
        emitNameChange() {
            this.$emit('onNameChanged', this.$v.scene.name.$model)
        },
        onFileSelected(fileSelectionEvent){
            this.$v.scene.containerName.$model = fileSelectionEvent.container;
            this.$v.scene.filePath.$model = fileSelectionEvent.src;
            this.setPreviewSource(fileSelectionEvent.container, fileSelectionEvent.src)

        },
        setPreviewSource(container, filePath) {
            if(!this.$store.state.azureBlobData.data.sas.key || container !== this.$store.state.azureBlobData.data.sas.container) {
                this.$store.dispatch('getSharedAccessSignature', container).then(() => {
                    this.previewSource = 'https://' + this.$store.state.settings.storageDetails.storageAccount + '.blob.core.windows.net/' + container + '/' + filePath + '?' + this.$store.state.azureBlobData.data.sas.key;
                })
            } else {
                this.previewSource = 'https://' + this.$store.state.settings.storageDetails.storageAccount + '.blob.core.windows.net/' + container + '/' + filePath + '?' + this.$store.state.azureBlobData.data.sas.key;
            }
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.scene[name];
            return $dirty ? !$error : null;
        },
        onLoadedVideoMetadata(event) {
          this.$emit('maxSmoothWindowChaged', Math.floor(event.target.duration / 2));
        },
        isFormValid(){
            const isValid = !this.$v.scene.$invalid;
            if(!isValid)
                this.touchInvalidControls();
            return isValid;
        },
        touchInvalidControls() {
            Object.keys(this.$v.scene).filter(key => key.indexOf("$") === -1).forEach(key => {
                const control = this.$v.scene[key];
                if(control.$invalid){
                    control.$touch();
                }
            });
        }
    }
}

</script>
<style lang="scss">
    .hidden {
      display: none !important;
    }
    .aoi-form-wrapper{
        .scene-preview-video-player{
            margin-top: 5px;
        }
        .edit-readonly{
            .aoi-label {
                font-size: 16px!important;
            }
            .aoi-field {
                font-size: 14px!important;
            }
        }
        .scene-form-group{
            height: 70px;
            .hidden {
                display: none;
            }
            .file-name-field{
                box-shadow: none!important;
                border: 1px solid #4B5561!important;
            }
        }
        .wrapper {
            height: 90px;
            margin-top: 25px!important;
        }
        .post-processing-wrapper{
            margin-top: 35px!important;
        }
        .subtitle-text{
            padding-bottom: 10px;
            width: 300px;
            color: #ced6e0;
            font-size: 14px;
        }

    }
    @media screen and (min-width: 2000px){
        .scene-wrapper {
            flex-direction: row!important;
            flex-wrap: nowrap;
            .video-wrapper{
                margin-left: 100px;
                .scene-preview-video-player{
                    > video {
                        height: 300px;
                    }
                }
            }
        }
    }
</style>
